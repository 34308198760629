import useTranslation from '@helpers/useTranslation';
import React, { FC } from 'react';
import { Button } from 'react-bulma-components';
import * as styles from './GoogleButton.module.scss';

interface GoogleButtonProps {
  action: 'login' | 'register';
  href: string;
}

const GoogleButton: FC<GoogleButtonProps> = ({ action, href }) => {
  const { t } = useTranslation('auth');

  return (
    <Button size="medium" href={href} renderAs="a" className={styles.googleButton}>
      {t(action === 'login' ? 'googleLogin' : 'googleSignUp')}
    </Button>
  );
};

export default GoogleButton;
