import { useAuth } from '@context/AuthContext';
import { AuthType } from '@helpers/AuthClient';
import RegisterView from '@views/RegisterView';
import { navigate, PageProps } from 'gatsby';
import React, { FC, useEffect } from 'react';
import { DASHBOARD_PAGE } from '../../constants';

interface LocationState {
  email?: string;
}

const RegisterPage: FC<PageProps<null, null, LocationState | null>> = ({ location: { state } }) => {
  const { isAuthenticated, isReady } = useAuth();

  useEffect(() => {
    if (isReady && isAuthenticated) {
      navigate(DASHBOARD_PAGE);
    }
  }, [isAuthenticated, isReady]);

  return isReady && !isAuthenticated ? (
    <RegisterView authType={AuthType.Web} defaultValues={{ email: state?.email }} />
  ) : null;
};

export default RegisterPage;
