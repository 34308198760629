import React, { FC } from 'react';
import * as styles from './Divider.module.scss';

const Divider: FC = ({ children }) => {
  return (
    <div className={styles.divider}>
      <span className={styles.line} />
      {children && <span className={styles.content}>{children}</span>}
      <span className={styles.line} />
    </div>
  );
};

export default Divider;
