/* eslint-disable jsx-a11y/no-autofocus */
import Divider from '@components/Divider';
import GoogleButton from '@components/GoogleButton';
import PageMeta from '@components/PageMeta';
import TextField from '@components/TextField';
import Translate from '@components/Translate';
import { useAuth } from '@context/AuthContext';
import { AuthType } from '@helpers/AuthClient';
import getAuthPageUrl from '@helpers/getAuthPageUrl';
import isValidEmail from '@helpers/isValidEmail';
import isValidPassword from '@helpers/isValidPassword';
import useTranslation from '@helpers/useTranslation';
import AuthTemplate from '@templates/AuthTemplate';
import { Link, navigate } from 'gatsby';
import React, { FC, useState } from 'react';
import { Button, Form } from 'react-bulma-components';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

interface FormValues {
  email: string;
  name: string;
  password: string;
}

interface RegisterViewProps {
  authType: AuthType;
  defaultValues?: Partial<FormValues>;
}

const RegisterView: FC<RegisterViewProps> = ({ authType, defaultValues }) => {
  const { t } = useTranslation(['auth', 'validation', 'pages']);

  const { authClient } = useAuth();

  const formProps = useForm<FormValues>({ defaultValues });

  const [isLoading, setIsLoading] = useState(false);

  const handleFormSubmit: SubmitHandler<FormValues> = ({ email, name, password }) => {
    setIsLoading(true);

    authClient
      .register(name, email, password, authType)
      .then(() => {
        navigate(verifyEmailUrl, {
          state: { email },
        });
      })
      .catch(error => {
        if (error.code === 'auth/email-already-in-use') {
          formProps.setError('email', {
            message: t('validation:usernameExists'),
            shouldFocus: true,
          });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const email = formProps.watch('email');

  const googleLoginUrl = getAuthPageUrl('googleLogin', authType);
  const loginUrl = getAuthPageUrl('login', authType);
  const verifyEmailUrl = getAuthPageUrl('verifyEmail', authType);

  return (
    <AuthTemplate>
      <PageMeta description={t('pages:signUpDescription')} title={t('pages:signUp')} />
      <AuthTemplate.Content>
        <FormProvider {...formProps}>
          <form onSubmit={formProps.handleSubmit(handleFormSubmit)}>
            <GoogleButton action="register" href={googleLoginUrl} />
            <Divider>{t('common:or')}</Divider>
            <TextField<FormValues>
              autoComplete="name"
              autoFocus
              id="name"
              label={t('common:name')}
              name="name"
              type="typee"
              validation={{
                required: t('validation:required', { field: t('common:name') }),
              }}
            />
            <TextField<FormValues>
              autoComplete="username"
              id="email"
              label={t('common:email')}
              name="email"
              type="email"
              validation={{
                required: t('validation:required', { field: t('common:email') }),
                validate(email) {
                  return isValidEmail(email) ? true : t('validation:invalidEmail');
                },
              }}
            />
            <TextField<FormValues>
              Caption={
                <>
                  {t('minimumEightCharacters')}
                  <br />
                  {t('containsMixedCase')}
                  <br />
                  {t('containsNumberAndSymbol')}
                </>
              }
              id="password"
              label={t('password')}
              name="password"
              type="password"
              validation={{
                required: t('validation:required', { field: t('password') }),
                validate(password) {
                  return isValidPassword(password) ? true : t('validation:invalidPassword');
                },
              }}
            />
            <Form.Field kind="group">
              <Form.Control>
                <Button color="primary" loading={isLoading}>
                  {t('signUp')}
                </Button>
              </Form.Control>
            </Form.Field>
          </form>
        </FormProvider>
        <AuthTemplate.Footer>
          <Translate
            components={{ login: <Link to={loginUrl} state={{ email }} /> }}
            message={t('alreadyHaveAccount')}
          />
        </AuthTemplate.Footer>
      </AuthTemplate.Content>
    </AuthTemplate>
  );
};

export default RegisterView;
